import * as ACTIONS from '../store/actions/actions'

import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline'
import { BsArrowBarLeft, BsArrowClockwise } from 'react-icons/bs'
import { Dialog, Menu, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import {
  renderNotificationActus,
  renderNotificationMessages,
} from '../screens/Home'

// Store
import { Context } from '../store/Store'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import adherentsSvg from '../svg/ic-adherents-w.svg'
// Icones
import annoncesSvg from '../svg/ic-livre-w.svg'
import blsSvg from '../svg/ic-livre-w.svg'
import documentsSvg from '../svg/ic-boite-w.svg'
import homeSvg from '../svg/ic-home-w.svg'
import messagesSvg from '../svg/ic-ecran-w.svg'
import newsSvg from '../svg/ic-livre-w.svg'
import planificationSvg from '../svg/ic-calendrier-w.svg'
import priceSvg from '../svg/ic-etiquettes-w.svg'
import produitsSvg from '../svg/ic-feuilles-w.svg'
import profilSvg from '../svg/ic-perso-w.svg'

// import { Link } from 'react-router-dom'
// import PropTypes from 'prop-types'
// import adherentsSvg from '../svg/ic-adherents-w.svg'

const navigationDeclarations = [
  {
    link: '/annonces',
    name: 'Annonces',
    href: '#',
    icon: annoncesSvg,
    current: false,
  },
  { link: '/bls', name: 'Bls', href: '#', icon: blsSvg, current: false },
  // {
  //   link: '/notations',
  //   name: 'Notations hadenn',
  //   href: '#',
  //   icon: produitsSvg,
  //   current: false,
  // },
  {
    link: '/planification',
    name: 'Planification',
    href: '#',
    icon: planificationSvg,
    current: false,
  },
  {
    link: '/achats',
    name: 'Achats groupés',
    href: '#',
    icon: planificationSvg,
    current: false,
  },
]

const navigationCoop = [
  {
    link: '/biobreizh',
    name: 'Biobreizh',
    href: '#',
    icon: produitsSvg,
    current: false,
  },
  {
    link: '/adherents',
    name: 'Annuaire',
    href: '#',
    icon: adherentsSvg,
    current: false,
  },
  {
    link: '/news',
    name: 'Actualités',
    href: '#',
    icon: newsSvg,
    current: false,
  },
  {
    link: '/docs',
    name: 'Documents',
    href: '#',
    icon: documentsSvg,
    current: false,
  },
  {
    link: '/messages',
    name: 'Messages',
    href: '#',
    icon: messagesSvg,
    current: false,
  },
]

const navigationProduits = [
  {
    link: '/qualite',
    name: 'Qualité',
    href: '#',
    icon: produitsSvg,
    current: false,
  },
  {
    link: '/fiches',
    name: 'Légumes',
    href: '#',
    icon: produitsSvg,
    current: false,
  },
  { link: '/price', name: 'Prix', href: '#', icon: priceSvg, current: false },
]

const navigationAides = [
  // {
  //   link: '/po/po-home-pec',
  //   name: 'Aides ' + new Date().getFullYear(),
  //   href: '#',
  //   icon: produitsSvg,
  //   current: false,
  // },
  // {
  //   link: '/po/po-home-pav',
  //   name: 'Projet ' + (new Date().getFullYear() + 1),
  //   href: '#',
  //   icon: produitsSvg,
  //   current: false,
  // },
  {
    link: '/po/po-mes-demandes',
    name: 'Mes demandes',
    href: '#',
    icon: produitsSvg,
    current: false,
  },
]

const navigationProfil = [
  {
    link: '/profil',
    name: 'Mon profil',
    href: '#',
    icon: profilSvg,
    current: false,
  },
  /*   {
    link: "mesdocs",
    name: "Mes documents",
    href: "#",
    icon: blsSvg,
    current: false,
  }, */
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const menulinks = (app, dispatch) => {
  return (
    <nav className="px-5 pb-24">
      <Link to="/" onClick={() => dispatch(ACTIONS.setMenuOpen(false))}>
        <span
          className={
            'hover:bg-white text-white hover:bg-opacity-30 group flex items-center p-2 font-normal rounded-md'
          }
        >
          <img src={homeSvg} alt="Icône" className="w-8 mr-4 text-black" />
          Accueil
        </span>
      </Link>

      <h3 className="bg-white bg-opacity-70 text-black py-1 px-2 my-2">
        Aides
      </h3>
      {navigationAides.map((item, i) => (
        <Link
          key={`navigationAides-${i}`}
          to={item.link}
          onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
        >
          <span
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-white text-white bg-opacity-30'
                : 'hover:bg-white text-white hover:bg-opacity-30',
              'group flex items-center p-2 font-normal rounded-md'
            )}
          >
            <img src={item.icon} alt="Icône" className="w-8 mr-4 text-black" />
            {item.name}
          </span>
        </Link>
      ))}

      <h3 className="bg-white bg-opacity-70 text-black py-1 px-2 my-2">
        Déclarations
      </h3>
      {navigationDeclarations.map((item, i) => (
        <Link
          key={`navigationDeclarations-${i}`}
          to={item.link}
          onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
        >
          <span
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-white text-white bg-opacity-30'
                : 'hover:bg-white text-white hover:bg-opacity-30',
              'group flex items-center p-2 font-normal rounded-md'
            )}
          >
            <img src={item.icon} alt="Icône" className="w-8 mr-4 text-black" />
            {item.name}
          </span>
        </Link>
      ))}
      <h3 className="bg-white bg-opacity-70 text-black py-1 px-2 my-2">Coop</h3>
      {navigationCoop.map((item, i) => (
        <Link
          key={`navigationCoop-${i}`}
          to={item.link}
          onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
        >
          <span
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-white text-white bg-opacity-30'
                : 'hover:bg-white text-white hover:bg-opacity-30',
              'group flex items-center p-2 font-normal rounded-md relative'
            )}
          >
            <img src={item.icon} alt="Icône" className="w-8 mr-4 text-black" />
            {item.name}

            {app.notifications.length > 0 && (
              <div className="absolute left-[55px] top-4">
                {item.link === '/messages' && renderNotificationMessages(app)}
                {item.link === '/news' && renderNotificationActus(app)}
              </div>
            )}
          </span>
        </Link>
      ))}
      <h3 className="bg-white bg-opacity-70 text-black py-1 px-2 my-2">
        Produits
      </h3>
      {navigationProduits.map((item, i) => (
        <Link
          key={`navigationProduits-${i}`}
          to={item.link}
          onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
        >
          <span
            key={item.name}
            href={item.href}
            className={classNames(
              item.current
                ? 'bg-white text-white bg-opacity-30'
                : 'hover:bg-white text-white hover:bg-opacity-30',
              'group flex items-center p-2 font-normal rounded-md'
            )}
          >
            <img src={item.icon} alt="Icône" className="w-8 mr-4 text-black" />
            {item.name}
          </span>
        </Link>
      ))}
      <h3 className="bg-white bg-opacity-70 text-black py-1 px-2 my-2">
        Configuration
      </h3>
      <div className="cursor-pointer">
        <div
          className="flex"
          onClick={() => {
            document.location = '/'
          }}
        >
          <span className="flex justify-center items-center w-7 h-7 rounded-full bg-white mr-2">
            <BsArrowClockwise />
          </span>
          <span className="text-white">Recharger</span>
        </div>
      </div>
    </nav>
  )
}

const SideMenu = () => {
  const { app, dispatch } = React.useContext(Context)
  return (
    <>
      <Transition.Root show={app.menuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={() => dispatch(ACTIONS.setMenuOpen(false))}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-annonces">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-6 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
                  >
                    <span className="sr-only">Fermer</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex justify-between  px-4">
                <div className="w-16 h-16 bg-white rounded-full">
                  {' '}
                  <Link
                    to="/profil"
                    onClick={() => dispatch(ACTIONS.setMenuOpen(false))}
                  >
                    <div className="flex-shrink-0 flex align-center">
                      <img
                        className="h-16 w-16 rounded-full"
                        src={app.profil.photo_profil}
                        alt=""
                      />
                      <span className="my-auto ml-2 text-xs text-white underline">
                        {app.profil.title}
                      </span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="mt-5 flex-1 h-0 pb-24 overflow-y-auto">
                {menulinks(app, dispatch)}
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 z-40">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow pt-5 bg-annonces overflow-y-auto">
          <div className="flex justify-between  px-4">
            <div className="w-16 h-16 bg-white rounded-full shadow border-white">
              <Link to="/profil">
                <div className="flex-shrink-0 flex align-center">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={app.profil.photo_profil}
                    alt=""
                  />
                  <span className="my-auto ml-2 text-xs text-white underline">
                    {app.profil.title}
                  </span>
                </div>
              </Link>
            </div>
          </div>
          <div className="mt-5 flex-1 flex flex-col">
            {menulinks(app, dispatch)}
          </div>
        </div>
      </div>
    </>
  )
}

SideMenu.propTypes = {
  setLoading: PropTypes.func,
}

export default SideMenu
